import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Sorry, that page can't be found</h1>
  </Layout>
)

export default NotFoundPage
